<script>
import { ArrowUpIcon, ShieldIcon, CpuIcon, VideoIcon } from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import countTo from "vue-count-to";

import Navbar from "@/components/navbar";
import Pricing from "@/components/pricing";
import Switcher from "@/components/switcher";
import Features from "@/components/features";
import Footer from "@/components/footer";

/**
 * Index-classic-saas component
 */
export default {
  data() {
    return {
      plans: [],
      playerVars: {
        autoplay: 1,
      },
      featuresData: [
        {
          icon: "uil uil-airplay",
          title: "Easy To Use",
          description:
            "Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean elementum semper.",
        },
        {
          icon: "uil uil-calendar-alt",
          title: "Daily Reports",
          description:
            "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word.",
        },
        {
          icon: "uil uil-clock",
          title: "Real Time Zone",
          description:
            "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.",
        },
      ],
    };
  },
  components: {
    Navbar,
    Pricing,
    Switcher,
    Footer,
    Features,
    ArrowUpIcon,
    Carousel,
    Slide,
    countTo,
    ShieldIcon,
    CpuIcon,
    VideoIcon
  },
  mounted() {
    this.$http.get('/api/subscriptions').then(response => {
      this.plans = response.data.plans
    }).catch(error => {
      console.log('error', error)
    })
  }
};
</script>

<template>
  <div>
    <Navbar />
    <!-- Hero Start -->
    <section class="bg-half-170 d-table w-100 overflow-hidden" id="home">
      <div class="container">
        <div class="row align-items-center pt-5">
          <div class="col-lg-7 col-md-6">
            <div class="title-heading">
              <h1 class="heading mb-3">
                Everything you <br />
                need to do <br />
                better work
              </h1>
              <p class="para-desc text-muted">
                Launch your campaign and benefit from our expertise on designing
                and managing conversion centered bootstrap4 html page.
              </p>
              <div class="mt-4 pt-2">
                <router-link
                    class="btn btn-primary"
                    to="/buy"
                >
                  Buy Now
                </router-link>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="classic-saas-image position-relative">
              <div class="bg-saas-shape position-relative">
                <img
                  src="images/saas/classic01.png"
                  class="mx-auto d-block"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Section Start -->
    <section class="section overflow-hidden">

      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
            <div class="section-title mr-lg-4">
              <h1 class="title mb-3">Why Choose us ?</h1>
              <p class="para-desc text-muted">
                Launch your campaign and benefit from our expertise on designing
                and managing conversion centered bootstrap4 html page.
              </p>

              <div class="row">
                <div class="col-12">
                  <div class="d-flex align-items-center pt-4">
                    <h2>
                      <shield-icon
                        class="fea icon-m-md text-primary"
                      ></shield-icon>
                    </h2>
                    <div class="ml-3">
                      <h5>Fully Secured</h5>
                      <p class="text-muted mb-0">
                        Moreover, in Latin only words at the beginning of
                        sentences are capitalized.
                      </p>
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-12">
                  <div class="d-flex align-items-center pt-4">
                    <h2>
                      <cpu-icon class="fea icon-m-md text-primary"></cpu-icon>
                    </h2>
                    <div class="ml-3">
                      <h5>Best Performance</h5>
                      <p class="text-muted mb-0">
                        If the fill text is intended to illustrate the
                        characteristics of sometimes.
                      </p>
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-12 pt-4">
                  <a href="javascript:void(0)" class="btn btn-outline-primary"
                    >Install Now <i class="mdi mdi-chevron-right"></i
                  ></a>
                </div>
                <!--end col-->
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-7 order-1 order-lg-2">
            <div class="saas-feature-shape-right position-relative">
              <img
                src="images/saas/classic02.png"
                class="img-fluid mx-auto d-block rounded shadow"
                alt=""
              />
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Our Pricing Rates</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <Pricing :plans="plans" />
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="rounded bg-primary p-lg-5 p-4">
          <div class="row align-items-end">
            <div class="col-md-8">
              <div class="section-title text-md-left text-center">
                <h4 class="title mb-3 text-white title-dark">
                  Start your free 2 week trial today
                </h4>
                <p class="text-white-50 mb-0">
                  Start working with Landrick that can provide everything you
                  need to generate awareness, drive traffic, connect.
                </p>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 mt-sm-0">
              <div class="text-md-right text-center">
                <a href="javascript:void(0)" class="btn btn-light"
                  >Get Started</a
                >
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
      </div>
      <!--end container-->
    </section>
    <!--end section-->

    <!-- Section End -->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.modal-content {
  background-color: transparent;
}
</style>
